<template>
  <div>
    <v-data-table disable-sort :loading="loadingTable" :headers="headers" :items="checkouts" class="elevation-1"
      :page-count="numberOfPagesPaginate" :page="currentPagePaginate" :server-items-length="totalStagesPaginate"
      :options.sync="options" :footer-props="{
        itemsPerPageText: 'Itens por pagina',
        itemsPerPageOptions: [5, 10, 15, 50],
        showFirstLastPage: true,

      }">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Contas Avulsas</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-badge :content="bagFilter" :value="bagFilter" bordered color="error" overlap>
            <v-btn large outlined rounded color="primary" @click="dialogFilter = true"><v-icon>{{ icons.mdiFilter
                }}</v-icon>filtros</v-btn>
          </v-badge>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>

      <template v-slot:item.id="{ item }">
        <div style="font-size: 18px">{{ item.id }}</div>
      </template>
      <template v-slot:item.description="{ item }">
        <div style="font-size: 18px">{{ item.description }}</div>
      </template>
      <template v-slot:item.current_reserve_id="{ item }">
        <div style="font-size: 18px">{{ item.current_reserve_id }}</div>
      </template>


      <template v-slot:item.status_time="{ item }">
        <div style="font-size: 18px">{{ formatDate(item.status_time) }}</div>
      </template>

      <template v-slot:item.status="{ item }">
        <v-chip v-if="item.status == 'open'" color="error">Pendente</v-chip>
        <v-chip v-if="item.status == 'checkout'" color="success">Checkout</v-chip>
      </template>

      <template v-slot:item.guests="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span style="font-size: 18px" v-bind="attrs" v-on="on">{{ item.current_accommodation.guest[0].name }}</span>
          </template>
          <span style="font-size: 18px">{{ joinGuest(item) }}</span>
        </v-tooltip>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon large color="success" @click="sendToCheckout(item)" v-bind="attrs" v-on="on">
              <v-icon>
                {{ icons.mdiCash }}
              </v-icon>
            </v-btn>
          </template>
          <span>Pagar</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <NoDataTable></NoDataTable>
      </template>
      <template v-slot:loading>
        Carregando...
      </template>
    </v-data-table>

    <v-dialog v-model="dialogNote" width="600">
      <v-card>
        <BarTitleDialog title="Observações" @close="dialogNote = false" />
        <v-card-text>
          <v-data-table hide-default-footer :headers="headers_observation" :items="accommodation_observations"
            class="elevation-1" dense hide-default-header>
            <template v-slot:no-data>
              <NoDataTable>Nenhuma observação disponível</NoDataTable>
            </template>
          </v-data-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row>
            <v-col class="text-right">
              <v-btn x-large color="secondary" @click="dialogNote = false">fechar</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG FILTRO -->
    <v-dialog v-model="dialogFilter" width="700">
      <v-card>
        <BarTitleDialog title="FILTROS" @close="dialogFilter = false" />
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field v-model="filter.checkout_date" outlined type="date" label="Data Checkout"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row>
            <v-col class="text-left">
              <v-btn text color="error" x-large @click="clearFilter()">limpar filtros</v-btn>
            </v-col>

            <v-col class="text-right">
              <v-btn text color="secondary" x-large @click="dialogFilter = false">FECHAR</v-btn>
              <v-btn @click="confirmFilter()" color="primary" class="ml-2" x-large>aplicar</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <MsgDelete v-model="dialogDelete" @delete-yes="deleteItemConfirm()" />

  </div>
</template>

<script>
import MsgDelete from '@/components/MsgDelete.vue'
import NoDataTable from '@/components/NoDataTable.vue'
import { mdiPrinter, mdiFilter, mdiCash } from '@mdi/js'
import { mapActions, mapMutations } from 'vuex'
import BarTitleDialog from '@/components/BarTitleDialog.vue'


export default {
  components: {
    MsgDelete,
    NoDataTable,
    BarTitleDialog,
  },
  data: () => ({
    // FILTER
    filter: {},
    dialogFilter: false,
    note: '',
    dialogNote: false,
    loadingTable: false,
    totalStagesPaginate: 0,
    numberOfPagesPaginate: 0,
    currentPagePaginate: 0,
    dialogDelete: false,
    dialog: false,
    loading: false,
    headers: [
      {
        text: 'RESERVA',
        align: 'start',
        value: 'current_reserve_id',
      },
      {
        text: 'QUARTO',
        align: 'start',
        value: 'description',
      },
      {
        text: 'HÓSPEDES',
        align: 'start',
        value: 'guests',
      },
      {
        text: 'DATA',
        align: 'start',
        value: 'status_time',
      },
      {
        text: 'STATUS',
        align: 'center',
        value: 'status',
      },

      {
        text: '',
        align: 'start',
        value: 'actions',
        sortable: false,
      },
    ],
    checkouts: [],
    editedIndex: -1,
    editedItem: {
      description: '',
    },
    defaultItem: {
      description: '',
    },
    options: {
      itemsPerPage: 50,
      page: 1,
    },
    icons: {
      mdiPrinter,
      mdiFilter,
      mdiCash
    },
    accommodation_observations: [],
    headers_observation: [
      {
        text: 'Observações',
        sortable: false,
        align: 'start',
        value: 'description',
      },
      {
        text: '',
        align: 'end',
        value: 'action',
      },
    ],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Novo Pix' : 'Editar Pix'
    },
    bagFilter() {
      return Object.keys(this.filter).length
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },

    options: {
      handler() {
        this.initialize()
      },
    },
  },

  created() {
    // this.getCategory().then(response => {
    //   this.categoryItems = response.data
    // })

    this.initialize()
  },

  methods: {
    ...mapActions('outstanding', ['getOutsatnding']),

    ...mapMutations('checkout', [
      'setRoom',
      'setCheckout',
      'setStage',
      'clearCheckoutSeparete',
      'setOriginPartialPayment',
    ]),


    initialize() {
      this.loadingTable = true
      const { page, itemsPerPage } = this.options
      const payload = {
        itemsPerPage: itemsPerPage || 10,
        page,
      }

      this.getOutsatnding(payload)
        .then(response => {
          this.checkouts = response.data.data
          this.totalStagesPaginate = response.data.total
          this.numberOfPagesPaginate = response.data.last_page
          this.currentPagePaginate = response.data.current_page
        })
        .finally(() => {
          this.loadingTable = false
        })
    },
    joinGuest(checkout) {
      if (checkout.accommodation) {
        let guests = checkout.accommodation.guest
        let guestsName = []
        guests.forEach(guest => {
          guestsName.push(guest.name)
        })
        return guestsName.join(', ')
      }
    },
    showNote(item) {
      let accommodation_id = item.accommodation.id
      this.getAccommodationObservations(accommodation_id).then(response => {
        this.accommodation_observations = response.data
      })
      this.dialogNote = true


    },
    // calcPayment(item) {
    //   const sum_payment = item.payment.reduce((acc, payment) => {
    //     return (
    //       acc +
    //       (parseFloat(payment.subtotal) +
    //         parseFloat(payment.tax) +
    //         parseFloat(payment.additional) -
    //         parseFloat(payment.discount))
    //     )
    //   }, 0)

    //   return this.formatMoney(sum_payment)
    // },
    clearFilter() {
      this.filter.checkout_date = ''
    },
    confirmFilter() {
      this.initialize()
      this.dialogFilter = false
    },
    formatMoney(value) {
      value = parseFloat(value)
      if (value != null) {
        return value.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      }
    },
    formatDateTime(date) {
      if (date) {
        const result = new Date(date)
        return (
          result.toLocaleDateString('pt-BR', {
            timeZone: 'UTC',
          }) +
          ' ' +
          result.toLocaleTimeString('pt-BR')
        )
      }
    },
    exportPdf(checkout) {
      checkout.loading_print = true
      this.printCheckoutPdf(checkout)
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', 'voucher.pdf')
          document.body.appendChild(fileLink)
          fileLink.click()
        })
        .catch(response => {
          console.log(response)
        })
        .finally(() => {
          this.checkouts.forEach(item => {
            item.loading_print = false
          })
          //armegagem para força a reação do vue
          let backup = this.checkouts
          this.checkouts = []
          this.checkouts = backup
        })
    },
    joinGuest(checkout) {
      if (checkout.current_accommodation) {
        let guests = checkout.current_accommodation.guest
        let guestsName = []
        guests.forEach(guest => {
          guestsName.push(guest.name)
        })
        return guestsName.join(', ')
      }
    },
    formatDate(dateString) {
      if (!dateString) return null
      const [date, time] = dateString.split(' ')
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    sendToCheckout(item) {
      this.$emit('close')
      this.$router.push({
        name: 'checkout_outstanding',
      })
      this.setStage('payment')
      // this.setRoom(this.room)

      this.clearCheckoutSeparete()
      this.storeCheckout(item)
      this.setOriginPartialPayment('checkout')
    },

    storeCheckout(item) {
      this.setRoom(item)
      let products = item.current_accommodation.product
      let services = item.current_accommodation.service
      products.map(product => (product.origin = 'product')) //propriedade define no checkout quem é produto ou serviço
      services.map(service => (service.origin = 'service')) //propriedade define no checkout quem é produto ou serviço
      item.accommodationItemsCurrent.map(item => {
        item.origin = 'accommodation'
        item.pivot = { quantity: 1 }
        item.sale_price = item.value
        item.description = 'HOSPEDAGEM ' + this.formatDate(item.date_start) + ' - ' + this.formatDate(item.date_end)
        return item
      })
      item.current_accommodation.extra_items.map(item => {
        item.origin = 'extra_tarif'
        item.pivot = { quantity: 1 }
        item.sale_price = item.value
        return item
      })
      const accommodation_item = item.accommodationItemsCurrent
      const accommodation_exta = item.current_accommodation.extra_items
      this.setCheckout(products.concat(services, accommodation_item, accommodation_exta)) //junta produto e servico para mostrar no checkout
    },

  },
}
</script>
